import React, { useEffect, useState } from "react";
// Core components

//Css Here
import DashboardBox from "@components/DashboardBox";
import { RaffleDataBased } from "@type/Raffle";
import * as S from "./style";

//Css Here
import { getRaffle } from "@network/Raffle";
import { SCREEN } from "@type/index";
import RaffleSwiperRender from "./Components/RaffleSwipeRender";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";
import DecryptedData from "@utils/decrypdata.service";
import { EncryptService } from "@utils/encrypt.service";
import Img from "@assets/img";
import { CreateTicket } from "@network/Dashboard";
import AlertPopup from "@components/AlertPopup";
import { routes } from "@const/routes";
import { useNavigate } from "react-router-dom";

const privateKey = process.env.REACT_APP_PRIVATE_KEY as string;

const Dashboard = ({ title }: { title: string }) => {
  const [raffleData, setRaffleData] = useState<RaffleDataBased>();
  const [nextTime, setNextTime] = useState();
  const isLoggedIn = useSelector((state: RootState) => state.auth.token);
  const [isOpenCompletedAlert, setIsOpenCompletedAlert] = useState(false);
  const navigate = useNavigate();
  const nextRaffle = {
    title: "Next Game In:",
    raffle: nextTime,
    buttonText: "Join Now!",
  };

  const nextBlankRaffle = {
    title: "Next Game In:",
    blank: "Waiting",
  };

  const onFinish = () => {
    setIsOpenCompletedAlert(false);
  };

  const questionair = {
    title: "Join questionair to get free stickets",
    buttonText: "Join Questionair",
  };

  const createTicketWithCode = async (data: any) => {
    const parsedPayload = JSON?.parse(data);
    const response = await CreateTicket(parsedPayload.ticket);
    if (response.data) {
      setIsOpenCompletedAlert(true);
      localStorage.removeItem("giftCode");
    } else {
      localStorage.removeItem("giftCode");
    }
  };

  const limit = 6;

  useEffect(() => {
    const fetchAll = async () => {
      const data = {
        limit: limit,
        offset: 0,
        type: "ALL",
        screen: SCREEN.DASHBOARD,
      };
      try {
        const response = await getRaffle(data);
        setNextTime(response.data.raffles.nextRaffle);
        setRaffleData(response.data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchAll();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const giftCode = localStorage.getItem("giftCode");
      if (giftCode) {
        const encrypt = new EncryptService();
        const payload = encrypt.decryptUseAES(
          giftCode,
          privateKey,
          DecryptedData
        );
        createTicketWithCode(payload);
      }
    }
  }, [isLoggedIn]);

  return (
    <>
      <S.Title>{title}</S.Title>
      <S.Top>
        {nextTime ? (
          <DashboardBox {...nextRaffle} />
        ) : (
          <DashboardBox {...nextBlankRaffle} />
        )}
      </S.Top>
      <RaffleSwiperRender raffleData={raffleData} />
      <AlertPopup
        isOpen={isOpenCompletedAlert}
        header="Congratulation"
        title={
          <>
            Thank you for your participation!
            <br />
            You have 1 Free Ticket!
          </>
        }
        icon={Img.StarIcon}
        leftButton={{
          title: "View Your Tickets",
          onClick: () => navigate(routes.ticket),
        }}
        onDismiss={onFinish}
      />
    </>
  );
};

export default Dashboard;
