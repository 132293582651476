import React, { useEffect, useState } from "react";
import * as S from "../../style";
import DownIconGift from "@assets/img/down-gif.gif";
import UpIconGift from "@assets/img/up-gif.gif";

interface Props {
  title?: string;
  iconSrc: string;
  bgTitle: string;
  bgTitleCollapsed: string;
  colorIcon: string;
  children: React.ReactNode;
  toggleIcon?: React.ReactNode;
  index?: number;
  openIndex: any;
  setOpenIndex: any;
  setLeaderboardOpen: any;
}
export default function DiscoverMore({
  title,
  iconSrc,
  children,
  openIndex,
  setOpenIndex,
  index,
  bgTitle,
  bgTitleCollapsed,
  colorIcon,
  setLeaderboardOpen,
}: Props) {
  const [isCollapsed, setIsCollapse] = useState(false);
  const [height, setHeight] = useState("auto");
  const onShowContent = () => {
    setIsCollapse(true);
    setHeight("90");
    setOpenIndex(index);
    if (index === 0) {
      setLeaderboardOpen(true);
    }
  };

  const positionIcon: React.CSSProperties = {
    position: "absolute",
    top: "-9px",
    left: "10px",
  };

  const cssTitle: React.CSSProperties =
    index === 0
      ? {
          marginLeft: "10px",
          fontWeight: "bold",
        }
      : {
          fontWeight: "bold",
        };

  const onCloseContent = () => {
    setOpenIndex(null);
    setIsCollapse(false);
    setHeight("auto");
    if (index === 0) {
      setLeaderboardOpen(false);
    }
  };
  useEffect(() => {
    if (openIndex === index) {
      setIsCollapse(true);
      setHeight("90");
    } else {
      setIsCollapse(false);
      setHeight("auto");
    }
  }, [openIndex]);

  return (
    <S.ContainerLeaderBoard>
      {!isCollapsed ? (
        <S.TitleLeaderBoard height={height} backgroundImage={`url(${bgTitle})`}>
          <img
            style={{ ...positionIcon }}
            width={40}
            height={40}
            src={iconSrc}
          />
          <span style={{ ...cssTitle }}>{title}</span>
          <div
            onClick={onShowContent}
            style={{
              position: "absolute",
              bottom: "-10px",
              right: "163px",
              backgroundColor: `${colorIcon}`,
              borderRadius: "50%",
              width: "25px",
              height: "25px",
            }}
          >
            <img src={DownIconGift} height={16} width={16} />
          </div>
        </S.TitleLeaderBoard>
      ) : (
        <S.TitleLeaderBoard
          height={height}
          backgroundImage={`url(${bgTitleCollapsed})`}
        >
          <img
            style={{ ...positionIcon }}
            width={40}
            height={40}
            src={iconSrc}
          />
          <span style={{ ...cssTitle }}>{title}</span>
          <div
            onClick={onShowContent}
            style={{
              position: "absolute",
              bottom: "-10px",
              right: "154px",
              backgroundColor: `${colorIcon}`,
              borderRadius: "50%",
              width: "25px",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={DownIconGift} height={16} width={16} />
          </div>
        </S.TitleLeaderBoard>
      )}

      {openIndex === index && (
        <>
          <S.ContentLeaderBoard open={isCollapsed}>
            <div>{children}</div>
          </S.ContentLeaderBoard>
          <div>
            <div
              style={{
                position: "relative",
                bottom: "67px",
                left: "155px",
                backgroundColor: `#363636`,
                borderRadius: "50%",
                width: "25px",
                height: "25px",
                zIndex: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={onCloseContent}
            >
              <img src={UpIconGift} height={16} width={16} />
            </div>
          </div>
        </>
      )}
    </S.ContainerLeaderBoard>
  );
}
