import React, { useEffect, useState } from "react";
import avatar from "../../assets/img/header-bar/avatar.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PopUp from "@components/PopUp";
import { RootState, clearStateAndPersist } from "@store/store";
import { Link } from "react-router-dom";
import Img from "@assets/img";
import { jwtDecode } from "jwt-decode";
import * as S from "./style";
import { API_END_POINTS } from "@utils/EndPoint";
import { routes } from "@const/routes";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img from "@assets/img";
import { Inbox } from "@novu/react";
import AppearanceInbox from "./inbox.style";
import useComponentVisible from "src/hooks/useComponentVisible";
interface Props {
  backToDashboard?: boolean;
  isBack?: boolean;
  backlabel?: string;
  title?: string;
}

const applicationIdentifier =
  process.env.REACT_APP_NOVU_APPLICATION_IDENTIFIER || "";

const HeaderBar = ({
  isBack = false,
  backToDashboard = false,
  title,
  backlabel,
}: Props) => {
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [subscriberId, setSubscriberId] = useState("");
  const [subscriberIdHash, setSubscriberIdHash] = useState("");
  const { user } = useSelector((state: RootState) => state.auth);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const isLoggedIn = useSelector((state: RootState) => state.auth.token);
  const decodedToken = () => {
    if (isLoggedIn) {
      const decoded: any = jwtDecode(isLoggedIn);
      setSubscriberId(decoded.subId);
      setSubscriberIdHash(decoded.subIdHash);
    }
  };
  const navigate = useNavigate();

  const handleDropdown = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const logout = () => {
    setVisiblePopup(false);
    clearStateAndPersist();
    navigate("/");
  };

  const onBack = () => {
    if (isBack) return navigate(-1);
    navigate(routes.dashboard);
  };
  useEffect(() => {
    decodedToken();
  }, [isLoggedIn]);
  return (
    <S.Container>
      <S.Wrapper>
        <S.Row>
          {(isBack || backToDashboard) && (
            <S.BackButton onClick={onBack}>
              <div>
                <img src={Img.BackButton} />
                <span>{backlabel}</span>
              </div>
            </S.BackButton>
          )}
          <S.Title>{title}</S.Title>
        </S.Row>
        <Button
          hidden={!!user || !!isLoggedIn}
          type="button"
          onClick={() => navigate(API_END_POINTS.LOGIN)}
          className="btn-round mr-1 btn btn-primary mb-hide"
        >
          Login <FontAwesomeIcon icon={["fas", "sign-in"]} />
        </Button>

        {user && (
          <S.UserContainer>
            {subscriberId && subscriberIdHash && (
              <Inbox
                applicationIdentifier={applicationIdentifier}
                subscriberId={subscriberId}
                subscriberHash={subscriberIdHash}
                appearance={AppearanceInbox.appearanceInbox}
              />
            )}
            <S.Dropdown>
              <S.DropdownTop onClick={handleDropdown}>
                <S.Avatar>
                  <img src={avatar} />
                </S.Avatar>
                <h4>{user?.username ? user?.username : user?.phoneNumber}</h4>
                <img src={Img.ChevronDown} />
              </S.DropdownTop>
              <div ref={ref}>
                {isComponentVisible && (
                  <S.DropdownBottom>
                    <Link to={API_END_POINTS.PROFILE}>
                      <S.DropdownOption>
                        <img src={img.userLogo} />
                        <span>Profile</span>
                      </S.DropdownOption>
                    </Link>
                    <Link to={API_END_POINTS.TICKETS}>
                      <S.DropdownOption>
                        <img src={img.walletLogo} />
                        <span>My Ticket</span>
                      </S.DropdownOption>
                    </Link>
                    <Link to={API_END_POINTS.FAVORITE_RAFFLE}>
                      <S.DropdownOption>
                        <img src={img.heartLogo} />
                        <span style={{ textWrap: "nowrap" }}>
                          Favorite Raffle
                        </span>
                      </S.DropdownOption>
                    </Link>
                    <div style={{ marginLeft: 5 }}>
                      <S.DropdownOption onClick={() => setVisiblePopup(true)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M9.66667 10.3333L13 7M13 7L9.66667 3.66667M13 7H5M7 10.3333C7 10.9533 7 11.2633 6.93185 11.5176C6.74692 12.2078 6.20782 12.7469 5.51764 12.9319C5.26331 13 4.95332 13 4.33333 13H4C3.06812 13 2.60218 13 2.23463 12.8478C1.74458 12.6448 1.35523 12.2554 1.15224 11.7654C1 11.3978 1 10.9319 1 10V4C1 3.06812 1 2.60218 1.15224 2.23463C1.35523 1.74458 1.74458 1.35523 2.23463 1.15224C2.60218 1 3.06812 1 4 1H4.33333C4.95332 1 5.26331 1 5.51764 1.06815C6.20782 1.25308 6.74692 1.79218 6.93185 2.48236C7 2.7367 7 3.04669 7 3.66667"
                            stroke="#F47C9A"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span className="signout">Sign out</span>
                      </S.DropdownOption>
                    </div>
                  </S.DropdownBottom>
                )}
              </div>
            </S.Dropdown>
          </S.UserContainer>
        )}
      </S.Wrapper>

      <PopUp
        visible={visiblePopup}
        title="Log out"
        footer={true}
        onOk={() => {
          setVisiblePopup(false);
          logout();
        }}
        onCancel={() => setVisiblePopup(false)}
        okText="No"
        cancelText="Yes"
      >
        <S.PopUpContainer>
          <div className="popup-title">Do you want to logout this session?</div>
          <div>
            <img src={Img.BullsEye} alt="fire" width={132} />
          </div>
        </S.PopUpContainer>
      </PopUp>
    </S.Container>
  );
};

export default HeaderBar;
